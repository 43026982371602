<template>
    <div>
        <ts-page-title
            :title="$t('occupation.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('occupation.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper
                class="tw-flex tw-items-center tw-justify-between"
            >
                <Button type="info" ghost @click="addNew">{{
                    $t('addNew')
                }}</Button>
                <div class="tw-justify-end tw-space-x-2">
                    <Input
                        search
                        v-model="search"
                        :placeholder="$t('occupation.search')"
                        :style="width"
                        @on-focus="() => (width = 'width: 500px')"
                        @on-blur="() => (width = 'width: 300px')"
                    />
                </div>
            </ts-panel-wrapper>
            <Table
                :columns="columns"
                :data="resources"
                size="small"
                :loading="loading"
                max-height="700"
                stripe
            >
                <template slot-scope="{ row }" slot="sector">
                    {{ row.loan_sector ? (locale == 'kh' ? row.loan_sector.sector_kh : row.loan_sector.sector_en) : '' }}
                </template>
                <template slot-scope="{ row }" slot="action">
                    <a
                        href="#"
                        class="text-primary ml-2"
                        @click.prevent="onEdit(row)"
                        v-tooltip="$t('edit')"
                    >
                        <Icon type="ios-create" size="20" />
                    </a>
                    <Poptip
                        confirm
                        :title="$t('areYouSure')"
                        @on-ok="onDelete(row)"
                        :transfer="true"
                        :ok-text="$t('yes')"
                        :cancel-text="$t('no')"
                    >
                        <a
                            class="ml-2 text-danger"
                            v-tooltip="$t('delete')"
                            :disabled="row._deleting"
                        >
                            <i
                                class="fas fa-circle-notch fa-spin"
                                v-if="row._deleting"
                            ></i>
                            <Icon type="ios-trash" size="20" v-else />
                        </a>
                    </Poptip>
                </template>
            </Table>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-i-pagination>
            </div>

            <!-- form-action -->
            <Modal
                v-model="showForm"
                draggable
                sticky
                scrollable
                :mask="false"
                :footer-hide="true"
                :z-index="1022"
                :title="$t('occupation.pageTitle')"
            >
                <from-action
                    ref="form_action"
                    @cancel="clearEdit"
                    @fetchData="fetchData"
                />
            </Modal>
        </ts-panel>
    </div>
</template>

<script>
import { debounce } from 'lodash'
import { mapState } from 'vuex'
import FromAction from './form'

export default {
    name: 'job-titleIndex',
    components: {
        FromAction
    },
    data () {
        return {
            loading: false,
            showForm: false,
            width: 'width: 300px'
        }
    },
    computed: {
        ...mapState('creditOperation/occupation', [
            'resources',
            'pagination'
        ]),
        search: {
            get () {
                return this.$store.state.creditOperation.occupation
                    .search
            },
            set (newValue) {
                this.$store.commit(
                    'creditOperation/occupation/SET_SEARCH',
                    newValue
                )
                this.$store.commit(
                    'creditOperation/occupation/RESET_CURRENT_PAGE'
                )
            }
        },
        columns () {
            return [
                {
                    title: this.$t('occupation.sector'),
                    slot: 'sector'
                },
                {
                    title: this.$t('occupation.occupationCode'),
                    key: 'occupation_code'
                },
                {
                    title: this.$t('occupation.occupationEn'),
                    key: 'occupation_en'
                },
                {
                    title: this.$t('occupation.occupationKh'),
                    key: 'occupation_kh'
                },
                {
                    title: this.$t('actions'),
                    key: 'action',
                    slot: 'action',
                    align: 'center',
                    className: 'tw-space-x-2',
                    width: 90
                }
            ]
        },
        locale() {
            return this.$root.$i18n.locale;
        }
    },
    methods: {
        fetchData (attributes) {
            this.loading = true
            this.$store
                .dispatch('creditOperation/occupation/fetch', {
                    ...attributes
                })
                .then(() => {
                    this.loading = false
                })
                .catch(error => {
                    this.loading = false
                    this.notice({ type: 'error', text: error.message })
                })
        },
        onEdit (record) {
            this.$store.commit(
                'creditOperation/occupation/SET_EDIT_DATA',
                record
            )
            this.showForm = true
            this.$refs.form_action.fetchResource()
        },
        clearEdit () {
            this.showForm = false
            this.$refs.form_action.clearInput()
            this.$store.commit(
                'creditOperation/occupation/SET_EDIT_DATA',
                {}
            )
        },
        addNew () {
            this.$store.commit(
                'creditOperation/occupation/SET_EDIT_DATA',
                {}
            )
            this.showForm = true
            this.$refs.form_action.fetchResource()
            this.$refs.form_action.clearInput()
        },

        async onDelete (record) {
            record._deleting = true
            this.$store
                .dispatch(
                    'creditOperation/occupation/destroy',
                    record.id
                )
                .then(response => {
                    this.fetchData()
                    record._deleting = false
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                })
                .catch(error => {
                    this.notice({ type: 'error', text: error.message })
                    record._deleting = false
                })
        },

        notice (not) {
            this.$Notice[not.type]({
                title: 'OCCUPATION',
                desc: not.text
            })
        }
    },
    watch: {
        search: debounce(function (search) {
            this.fetchData({ search })
        }, 500)
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('creditOperation/occupation/RESET_STATE')
        next()
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.fetchData()
        })
    }
}
</script>
